import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, TextField, Typography, Container, Grid } from '@mui/material';

function BMICalculator() {
  const [tabIndex, setTabIndex] = useState(0);
  const [metricHeight, setMetricHeight] = useState('');
  const [metricWeight, setMetricWeight] = useState('');
  const [imperialFeet, setImperialFeet] = useState('');
  const [imperialInches, setImperialInches] = useState('');
  const [imperialWeight, setImperialWeight] = useState('');
  const [bmi, setBmi] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    resetValues();
  };

  const resetValues = () => {
    setMetricHeight('');
    setMetricWeight('');
    setImperialFeet('');
    setImperialInches('');
    setImperialWeight('');
    setBmi(null);
  };

  // Calculate BMI as the user types
  useEffect(() => {
    if (tabIndex === 0) {
      // Imperial BMI Calculation
      if (imperialFeet && imperialInches && imperialWeight) {
        const heightInInches = parseFloat(imperialFeet) * 12 + parseFloat(imperialInches);
        const weightInPounds = parseFloat(imperialWeight);
        if (heightInInches > 0 && weightInPounds > 0) {
          const bmi = (703 * weightInPounds) / (heightInInches * heightInInches);
          setBmi(bmi.toFixed(2));
        } else {
          setBmi(null);
        }
      }
    } else if (tabIndex === 1) {
      // Metric BMI Calculation
      if (metricHeight && metricWeight) {
        const heightInMeters = parseFloat(metricHeight) / 100;
        const weightInKg = parseFloat(metricWeight);
        if (heightInMeters > 0 && weightInKg > 0) {
          const bmi = weightInKg / (heightInMeters * heightInMeters);
          setBmi(bmi.toFixed(2));
        } else {
          setBmi(null);
        }
      }
    }
  }, [tabIndex, imperialFeet, imperialInches, imperialWeight, metricHeight, metricWeight]);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        BMI Calculator
      </Typography>

      {/* Tabs for Imperial and Metric */}
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Imperial" />
        <Tab label="Metric" />
      </Tabs>

      {/* Tab Panels */}
      <Box mt={3}>
        {tabIndex === 0 && (
          <Box>
            <Typography variant="h6">Enter your height (Feet and Inches):</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Feet"
                  type="number"
                  value={imperialFeet}
                  onChange={(e) => setImperialFeet(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Inches"
                  type="number"
                  value={imperialInches}
                  onChange={(e) => setImperialInches(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Typography variant="h6">Enter your weight (Pounds):</Typography>
            <TextField
              label="Weight (Pounds)"
              type="number"
              value={imperialWeight}
              onChange={(e) => setImperialWeight(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            <Typography variant="h6">Enter your height (Centimeters):</Typography>
            <TextField
              label="Height (cm)"
              type="number"
              value={metricHeight}
              onChange={(e) => setMetricHeight(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Typography variant="h6">Enter your weight (Kilograms):</Typography>
            <TextField
              label="Weight (kg)"
              type="number"
              value={metricWeight}
              onChange={(e) => setMetricWeight(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Box>
        )}
      </Box>

      {bmi && (
        <Box mt={3} textAlign="center">
          <Typography variant="h5">Your BMI is: {bmi}</Typography>
        </Box>
      )}
    </Container>
  );
}

export default BMICalculator;
