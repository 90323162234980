import React from 'react';
import { AppBar, Toolbar, Typography, Menu, MenuItem, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import routesConfig from '../routesConfig';

const Navigation = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentGroup, setCurrentGroup] = React.useState(null);

  const handleClick = (event, group) => {
    setAnchorEl(event.currentTarget);
    setCurrentGroup(group);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentGroup(null);
  };

  // Group routes by their group property using a Map
//   const groupedRoutes = new Map();
//   routesConfig.forEach(route => {
//     if (!groupedRoutes.has(route.group)) {
//       groupedRoutes.set(route.group, []);
//     }
//     groupedRoutes.get(route.group).push(route);
//   });

  const groupedRoutes = {}
  routesConfig.forEach(route => {
	if (!groupedRoutes[route.group]) {
	  groupedRoutes[route.group] = [];
	}
	console.log(groupedRoutes);
	groupedRoutes[route.group].push(route);
  })

  console.log(groupedRoutes);
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          SimplifiedCalculators.com
        </Typography>
        {Object.keys(groupedRoutes).map((group) => (
          <div key={group}>
            <Button color="inherit" onClick={(event) => handleClick(event, group)}>
              {group}
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl) && currentGroup === group}
              onClose={handleClose}
            >
              {/* Add a title to the Menu */}
              <MenuItem disabled>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  {group}
                </Typography>
              </MenuItem>
              {groupedRoutes[group].map((route) => (
                <MenuItem
                  key={route.path}
                  component={Link}
                  to={route.path}
                  onClick={handleClose}
                >
                  {route.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;