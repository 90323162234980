import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routesConfig from './routesConfig';
import Directory from './routes/Directory';
import LayoutWrapper from './components/LayoutWrapper';
import Navigation from './components/Navigation';

function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Directory />} />
        {routesConfig.map((calculator, index) => (
          <Route key={index} path={calculator.path} element={<LayoutWrapper><calculator.component /></LayoutWrapper>} />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
