import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, TextField, Typography, Container, Grid, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import { feetAndInchesToCentimeters, centimetersToFeetAndInches, poundsToKilograms, kilogramsToPounds } from '../utils/UnitConversion';

function BMRCalculator() {
  const [tabIndex, setTabIndex] = useState(0);
  const [metricHeight, setMetricHeight] = useState('');
  const [metricWeight, setMetricWeight] = useState('');
  const [imperialFeet, setImperialFeet] = useState('');
  const [imperialInches, setImperialInches] = useState('');
  const [imperialWeight, setImperialWeight] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('male');
  const [bmr, setBmr] = useState(null);
  const [bmrUnit, setBmrUnit] = useState('calories/day');

  // Handle tab switching and convert units if switching between Metric and Imperial
  const handleTabChange = (event, newValue) => {
    if (newValue !== tabIndex) {
      if (newValue === 0) {
        // Switching to Imperial, convert Metric to Imperial
        if (metricHeight && metricWeight) {
          const { feet, inches } = centimetersToFeetAndInches(metricHeight);
          const pounds = kilogramsToPounds(metricWeight);
          setImperialFeet(feet !== '0' ? feet : '');
          setImperialInches(inches !== '0' ? inches : '');
          setImperialWeight(pounds !== '0' ? pounds.toFixed(2) : '');
        }
      } else {
        // Switching to Metric, convert Imperial to Metric
        if (imperialFeet && imperialInches && imperialWeight) {
          const cm = feetAndInchesToCentimeters(imperialFeet, imperialInches);
          const kg = poundsToKilograms(imperialWeight);
          setMetricHeight(cm !== 0 ? cm.toFixed(2) : '');
          setMetricWeight(kg !== 0 ? kg.toFixed(2) : '');
        }
      }
    }
    setTabIndex(newValue);
  };

  const calculateBMR = () => {
    if (tabIndex === 0) {
      // Imperial BMR Calculation
      if (imperialFeet && imperialInches && imperialWeight && age) {
        const heightInInches = parseFloat(imperialFeet) * 12 + parseFloat(imperialInches);
        const heightInCm = heightInInches * 2.54;
        const weightInKg = parseFloat(imperialWeight) * 0.453592;
        const calculatedBmr =
          gender === 'male'
            ? 10 * weightInKg + 6.25 * heightInCm - 5 * parseFloat(age) + 5
            : 10 * weightInKg + 6.25 * heightInCm - 5 * parseFloat(age) - 161;
        setBmr(calculatedBmr.toFixed(2));
        setBmrUnit('calories/day');
      }
    } else {
      // Metric BMR Calculation
      if (metricHeight && metricWeight && age) {
        const heightInCm = parseFloat(metricHeight);
        const weightInKg = parseFloat(metricWeight);
        const calculatedBmr =
          gender === 'male'
            ? 10 * weightInKg + 6.25 * heightInCm - 5 * parseFloat(age) + 5
            : 10 * weightInKg + 6.25 * heightInCm - 5 * parseFloat(age) - 161;
        setBmr(calculatedBmr.toFixed(2));
        setBmrUnit('kcal/day');
      }
    }
  };

  // Auto-calculate BMR when any relevant input changes
  useEffect(() => {
    calculateBMR();
  }, [tabIndex, imperialFeet, imperialInches, imperialWeight, metricHeight, metricWeight, age, gender]);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Calculator your Basal Metabolic Rate (BMR)
      </Typography>

      {/* Description */}
      <Typography variant="body1" align="center" gutterBottom>
        Your Basal Metabolic Rate (BMR) represents the number of calories your body needs at rest to maintain basic life functions, like breathing and digestion. It’s useful for determining how many calories you need to maintain or adjust your weight based on your activity level.
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        The formula used takes into account your weight, height, age, and gender. Try switching between units to see how your BMR changes!
      </Typography>

      {/* Tabs for Imperial and Metric */}
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Imperial" />
        <Tab label="Metric" />
      </Tabs>

      {/* Gender Selection (Centered) */}
      <Box display="flex" justifyContent="center" mt={3}>
        <Box>
          <FormLabel component="legend" align="center">Gender</FormLabel>
          <RadioGroup row value={gender} onChange={(e) => setGender(e.target.value)}>
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="female" control={<Radio />} label="Female" />
          </RadioGroup>
        </Box>
      </Box>

      {/* Tab Panels */}
      <Box mt={3}>
        {tabIndex === 0 && (
          <Box>
            <Typography variant="h6">Enter your height (Feet and Inches):</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Feet"
                  type="number"
                  value={imperialFeet}
                  onChange={(e) => setImperialFeet(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Inches"
                  type="number"
                  value={imperialInches}
                  onChange={(e) => setImperialInches(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Typography variant="h6">Enter your weight (Pounds):</Typography>
            <TextField
              label="Weight (Pounds)"
              type="number"
              value={imperialWeight}
              onChange={(e) => setImperialWeight(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            <Typography variant="h6">Enter your height (Centimeters):</Typography>
            <TextField
              label="Height (cm)"
              type="number"
              value={metricHeight}
              onChange={(e) => setMetricHeight(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Typography variant="h6">Enter your weight (Kilograms):</Typography>
            <TextField
              label="Weight (kg)"
              type="number"
              value={metricWeight}
              onChange={(e) => setMetricWeight(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Box>
        )}
      </Box>

      <Box mt={3}>
        <Typography variant="h6">Enter your age:</Typography>
        <TextField
          label="Age"
          type="number"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          fullWidth
          margin="normal"
        />
      </Box>

      {bmr && (
        <Box mt={3} textAlign="center">
          <Typography variant="h5">
            Your BMR is: {bmr} {bmrUnit}
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default BMRCalculator;
