import React from 'react';
import { Container, Box, Typography, Grid, Paper } from '@mui/material';

function LayoutWrapper({ children }) {
  return (
    <Container maxWidth="lg" sx={styles.container}>
      {/* <Box sx={styles.header}>
        <Typography variant="h6" align="center" sx={styles.title}>
          SimplifiedCalculators.com
        </Typography>
      </Box> */}

      <Grid container spacing={2} sx={styles.contentContainer}>
        {/* Left-side Google ad */}
        {/* <Grid item xs={12} md={2}>
          <Paper sx={styles.ad}>
            <Typography variant="body2" align="center">
              Google Ad (Left)
            </Typography>
          </Paper>
        </Grid> */}

        {/* Main calculator content */}
        <Grid item xs={12} md={12}>
          <Paper sx={styles.mainContent}>
            {children}
          </Paper>
        </Grid>

        {/* Right-side Google ad */}
        {/* <Grid item xs={12} md={2}>
          <Paper sx={styles.ad}>
            <Typography variant="body2" align="center">
              Google Ad (Right)
            </Typography>
          </Paper>
        </Grid> */}
      </Grid>

      {/* Bottom Google ad */}
      {/* <Box sx={styles.bottomAd}>
        <Paper sx={styles.ad}>
          <Typography variant="body2" align="center">
            Google Ad (Bottom)
          </Typography>
        </Paper>
      </Box> */}
    </Container>
  );
}

const styles = {
  container: {
    marginTop: '20px',
  },
  header: {
    marginBottom: '20px',
  },
  title: {
    fontWeight: 'bold',
    color: '#333',
  },
  contentContainer: {
    marginBottom: '20px',
  },
  mainContent: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 3px 5px rgba(0,0,0,0.1)',
  },
  ad: {
    padding: '10px',
    backgroundColor: '#e0e0e0',
    textAlign: 'center',
    minHeight: '150px',
    boxShadow: '0 3px 5px rgba(0,0,0,0.1)',
  },
  bottomAd: {
    marginTop: '20px',
  },
};

export default LayoutWrapper;
