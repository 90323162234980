// Converts height from feet and inches to centimeters
export function feetAndInchesToCentimeters(feet, inches) {
  const totalInches = parseFloat(feet) * 12 + parseFloat(inches);
  return totalInches * 2.54; // inches to cm
}

// Converts height from centimeters to feet and inches
export function centimetersToFeetAndInches(cm) {
  const totalInches = cm / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = parseFloat((totalInches % 12).toFixed(2));
  return { feet, inches };
}

// Converts weight from pounds to kilograms
export function poundsToKilograms(pounds) {
  return pounds * 0.453592; // pounds to kg
}

// Converts weight from kilograms to pounds
export function kilogramsToPounds(kg) {
  return kg / 0.453592; // kg to pounds
}
