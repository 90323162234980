import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Box, Card, CardContent, CardActionArea, CardMedia, Stack } from '@mui/material';
import routeConfig from '../routesConfig';

function Directory() {
  return (
    <Container maxWidth="lg" sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h4" align="center" sx={styles.title}>
          Choose a Calculator
        </Typography>
        <Typography variant="body1" align="center" sx={styles.subtitle}>
          Explore the various calculators we offer. Click on one to get started!
        </Typography>
      </Box>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={4}
        sx={{ flexWrap: 'wrap', justifyContent: 'center' }}
      >
        {routeConfig.map((calculator, index) => (
          <Box key={index} sx={styles.cardWrapper}>
            <Card sx={styles.card}>
              <CardActionArea component={Link} to={calculator.path}>
                <CardMedia
                  component="img"
                  height="140"
                  image={calculator.image}
                  alt={calculator.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {calculator.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {calculator.description}
                  </Typography>
                  <Typography variant="overline" color="textSecondary" display="block" mt={1} sx={{ fontSize: '0.3rem' }}>
                    {calculator.imgattribute}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
        ))}
      </Stack>
    </Container>
  );
}

const styles = {
  container: {
    marginTop: '40px',
  },
  header: {
    marginBottom: '30px',
  },
  title: {
    fontWeight: 'bold',
    color: '#333',
  },
  subtitle: {
    marginTop: '10px',
    color: '#666',
  },
  cardWrapper: {
    width: { xs: '100%', sm: '45%', md: '30%' },
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    maxWidth: 345,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)',
    },
  },
};

export default Directory;
