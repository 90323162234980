import BMICalculatorPage from './routes/BMICalculator';
import BMRCalculatorPage from './routes/BMRCalculator';

const routesConfig = [
  {
    name: 'BMI Calculator',
    path: '/bmi',
    component: BMICalculatorPage,
    description: 'Calculate your Body Mass Index (BMI) to see if you are within a healthy range.',
    group: 'Fitness',
    image: 'https://upload.wikimedia.org/wikipedia/commons/f/f7/Running_At_Sunrise_Malta_Color_Street_Photography_%28167052449%29.jpeg',
    imgattribute: 'Giuseppe Milo, CC BY 3.0 <https://creativecommons.org/licenses/by/3.0>, via Wikimedia Commons',
    imgsrc: 'https://commons.wikimedia.org/wiki/File:Running_At_Sunrise_Malta_Color_Street_Photography_(167052449).jpeg'
    
  },
  {
    name: 'BMR Calculator',
    path: '/bmr',
    component: BMRCalculatorPage,
    description: 'Find out your Basal Metabolic Rate (BMR), which is the number of calories you burn at rest.',
    group: 'Fitness',
    image: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Squat_press_move.jpg',
    imgattribute: 'ablight (AJB), CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons',
    imgsrc: 'https://commons.wikimedia.org/wiki/File:Squat_press_move.jpg'
  },
  // {
  //   name: 'Triangle solver',
  //   path: '/triangle',
  //   group: 'Math',
  // },
  // {
  //   name: 'Quadratic formula',
  //   path: '/quadratic',
  //   group: 'Math',
  // },
  // {
  //   name: 'Distance formula',
  //   path: '/cartesian-distance',
  //   group: 'Math',
  // },
  // {
  //   name: 'Mortgage calculator',
  //   path: '/mortgage',
  //   group: 'Finance',
  // }
  // Add more calculators here as needed
];

export default routesConfig;
